<template>
  <div>
    <mf-card-container title="Importar Termos e Condições" description="Importar .md com os termos e condições da campanha">
      <template #headers>
        <mf-button label="Ajuda" icon="help" icon-position="right" color="primary" @click="goToManual" />
      </template>

      <div>
        <div class="text-center my-4">
          <import-md @markdown-converted="updateMarkdown" @clear="clear" />
        </div>

        <v-row v-if="convertedMarkdown" no-gutters justify="center" class="py-3 pl-3">
          <markdown-display :markdown-content="convertedMarkdown" />
        </v-row>
      </div>
    </mf-card-container>

    <div class="d-flex flex-row align-center justify-end mt-2" style="gap: 8px;">
      <mf-button label="Cancelar" outlined color="error" @click="cancel" />
      <mf-action-buttons
        :primary-button="{
          text: 'Continuar',
          action: goNextStep,
          isVisible: true,
          isDisabled: !convertedMarkdown,
          isLoading: false
        }"
      />
    </div>
  </div>
</template>

<script>
import MarkdownDisplay from './MarkdownDisplay.vue'
import ImportMd from '@/modules/industry/components/ImportMd.vue'

export default {
  components: {
    ImportMd,
    MarkdownDisplay
  },
  data: () => ({
    title: '',
    convertedMarkdown: ''
  }),
  methods: {
    updateMarkdown(markdown) {
      this.convertedMarkdown = markdown
    },
    clear() {
      this.convertedMarkdown = ''
    },
    goNextStep() {
      this.$emit('nextStep', this.convertedMarkdown)
    },
    cancel() {
      this.$emit('cancel')
    },
    goToManual() {
      window.open('https://mercafacil.getoutline.com/doc/manual-backoffice-mercatrade-9LPk47mUBB', '_blank')
    }
  }
}
</script>

<template>
  <div>
    <mf-card-container title="Dados do calendário" description="Aqui você informa os dados gerais do calendário">
      <template #headers>
        <mf-button label="Ajuda" icon="help" icon-position="right" color="primary" @click="goToManual" />
      </template>

      <div>
        <v-form ref="form" class="px-5" lazy-validation style="background-color: none;">
          <div class="form-row">
            <div class="form-col" style="height: 100px; flex: 1">
              <label>Nome</label>
              <v-text-field v-model="name" outlined />
            </div>
            <div class="form-col" style="flex: 1">
              <label>Período</label>
              <mf-date-range-picker :initial-date.sync="startDate" :final-date.sync="endDate" />
            </div>
          </div>
          <div class="form-row">
            <div class="form-col">
              <v-checkbox v-model="test" label="Calendário de teste" outlined @change="clearDbName"></v-checkbox>
            </div>
            <div class="form-col" style="flex: 1">
              <label>DB Name:</label>
              <v-text-field v-model="testDbName" :disabled="!test" outlined />
            </div>
          </div>
        </v-form>
      </div>
    </mf-card-container>

    <div class="d-flex flex-row align-center justify-end mt-2" style="gap: 8px;">
      <mf-button label="Voltar" outlined color="error" @click="returnStep" />
      <mf-action-buttons
        :primary-button="{
          text: 'Salvar',
          action: save,
          isVisible: true,
          isDisabled: loading,
          isLoading: loading
        }"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      name: '',
      startDate: null,
      endDate: null,
      test: false,
      testDbName: ''
    }
  },
  methods: {
    save() {
      this.$emit('save', {
        name: this.name,
        start_date: new Date(this.startDate),
        end_date: new Date(this.endDate),
        test: this.test,
        test_db_name: this.testDbName
      })
    },

    returnStep() {
      this.$emit('returnStep')
    },

    clearDbName() {
      if (!this.test) this.testDbName = ''
    },

    goToManual() {
      window.open('https://mercafacil.getoutline.com/doc/manual-backoffice-mercatrade-9LPk47mUBB', '_blank')
    }
  }
}
</script>

<style lang="scss">
.form-row {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
}

.form-col {
  display: flex;
  flex-direction: column;
}
</style>

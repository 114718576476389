<template>
  <v-card>
    <v-card-text>
      <VueShowdown class="mt-1" :markdown="markdownContent" />
      <slot />
    </v-card-text>
  </v-card>
</template>

<script>
import { VueShowdown } from 'vue-showdown'

export default {
  components: {
    VueShowdown
  },
  props: {
    markdownContent: { type: String, default: '' }
  }
}
</script>
